// export const config = {
//     "tokenContract" : "0x8f0483125fcb9aaaefa9209d8e9d7b9c8b9fb90f",
//     "stoneContract" : "0x0",
//     "prePackContract" : "0x345ca3e014aaf5dca488057592ee47305d9b3e10",
//     "preBidContract" : "0x30753e4a8aad7f8597332e813735def5dd395028",
//     "preSaleContract" : "0xfb88de099e13c3ed21f80a7a1e49f8caecf10df6",
//     "fallbackUrl" : "http://127.0.0.1:8545",
//     "fallbackType" : "http",
//     "origin" : "http://localhost:3000",
//     "webService" : "http://localhost:8000/"
//     //"webService" : "https://api.cryptobeasties.com/"
// };

//ropsten

// export const config = {
//     "wearCrafterContract" : "0x1a71a10ff5274d563685e35c2a52ffcd6a261d28",
//     "infura" : "wss://ropsten.infura.io/ws"
// };


//rinkeby
// export const config = {
//     "tokenContract" : "0x99758305a613aeE7F4fB58059550E48095B09Bf2",
//     "stoneContract" : "0x0",
//     "prePackContract" : "0xaB427817b9b5fAe08bc52CaF321aD8118B2C3972",
//     "preBidContract" : "0x3fACb83506568d5A0D8227E57aD3B83a292695cD",
//     "preSaleContract" : "0x17b266aec317b2e7522134b386d87d5c912d5153",
//     "fallbackUrl" : "https://rinkeby.infura.io/v3/67773516f2344568abf453ba69683878",
//     //"fallbackUrl" : "http://127.0.0.1:8545",
//     "fallbackType" : "http",
//     "origin" : "http://localhost:3000",
//     "webService" : "https://api.cryptobeasties.com:9443/",
//     "infura" : "wss://rinkeby.infura.io/ws/v3/67773516f2344568abf453ba69683878"
// };

// Production
export const config = {
    "wearCrafterContract" : "0x6e8CaFe0124e79E323969D1786A0d8f75f5b8298",
    "fallbackUrl" : "https://mainnet.infura.io/v3/67773516f2344568abf453ba69683878",
    "fallbackType" : "http",
    "infura" : "wss://mainnet.infura.io/ws"
};