import React from 'react';
import { NavLink } from 'reactstrap';

class TermsPage extends React.Component {

    render() {
        return (
            <div className="fullpage bg-image landing-page">
                <div className="container h-100" id="about">
                    <div className="row h-100">
                        <div className="col-12 my-auto text-center text-white">
                            <h1>Terms of Use</h1>
                        </div>
                    </div>
            
                <div className="page-section bg-dark my-5" id="faq">
                    <div className="container wow fadeIn">
                        <div className="row my-5">
                            <div className="col-lg-12 text-left my-auto">
                                <hr className="colored" />
                                <h3>1. General Terms</h3>
						<p>The following terms govern the use of games provided by Decent Amusements, LLC on the Decentraland platform.
							These games include WonderMine Crafting Game, the Dragon Rush racing game, the Koko Jones shooting gallery game,  
							the Kingfisher fishing game, the Mystery Castle adventure game, and any other distinct games presented within the 
                            WonderZone Amusement Park district in Decentraland.
                            These terms also cover the creation and use of Decentraland Wearables and WonderZone ERC-721 
							Non-Fungible Tokens (NFTs)
							from within our Decentraland games. Collectively these games are refered to as the "WonderZone Games".</p>
						<p>WonderZone Games are hosted on the <a href="https://www.decentraland.org">Decentraland</a> blockchain-backed, 
						   3D multiplayer game platform. Decent Amusements does not control or manage the Decentraland platform, and cannot be responsible
						   for any problems arising from use of the Decentraland technologies.</p>
						<p>WonderZone Games also make use of 
							smart contracts (each, a "Smart Contract") to enable users to own, transfer, and trade unique digital assets,
							which can then be visualized on a website that the user can interact with (the "Site"). The Smart Contracts and
							the Site are collectively referred to in these Terms as the "App". Using the App, users can collect virtual goods 
							and then use those to "craft" NFTs which are minted and managed by Smart Contracts.</p>

						<p>Decent Amusements is making the App available to you. When you use the App, the Smart Contracts, or the Site, you
							 agree to these Terms of Use and any terms and conditions incorporated herein by reference
							(collectively, these "Terms").
						</p>
						<p>Decent Amusements provides the WonderZone Games as a service for the enjoyment of players. However we reserve the right to 
						   cancel this service if needed (though we would never do so without great deliberation and plenty of public warning and notice!).
						   We reserve to the right to ban specific players from WonderZone Games for any reason, based on our own judgment. 
						   Immediately bannable offenses include, <strong>but are not limited to</strong>:
						   <ul>
						   <li>Engaging in abusive or disruptive behavior directed at other players or Decent Amusements personnel, either in the games or in our Discord channels</li>
						   <li>Spamming advertising messages either in-game or in our Discord channels.</li>
						   <li>Attempting to hack, disrupt, or otherwise interfere with or directly call WonderZone Games server APIs or Smart Contracts</li>
						   <li>Attempting to steal digital assets from other players, or trick other players into revealing passwords, account information, 
						   or compromising information that could enable theft.</li>
						   <li>Exploiting for personal benefit, or to disrupt the game, known bugs that have been communicated within the community but not yet fixed.</li>
						   </ul></p>
						<p>
							PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE APP, THE SMART CONTRACTS, OR THE SITE. THESE TERMS GOVERN
							YOUR USE OF THE APP, THE SMART CONTRACTS, AND THE SITE, UNLESS DECENT AMUSEMENTS HAS EXECUTED A SEPARATE WRITTEN
							AGREEMENT WITH YOU FOR THAT PURPOSE. DECENT AMUSEMENTS IS ONLY WILLING TO MAKE THE APP, THE SMART CONTRACTS, AND THE
							SITE AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE TERMS. BY USING THE APP, THE SMART CONTRACTS, THE SITE, OR ANY
							PART OF THEM, YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS. IF YOU ARE
							ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL
							AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY"S BEHALF, IN WHICH CASE "YOU" WILL MEAN THAT ENTITY. IF YOU DO
							NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT ACCEPT ALL OF THESE TERMS, THEN DECENT AMUSEMENTS IS UNWILLING TO MAKE THE
							APP, THE SMART CONTRACTS, OR THE SITE AVAILABLE TO YOU. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS
							OR USE THE APP, THE SMART CONTRACTS, OR THE SITE.
						</p>
						<p></p>
						<h3>2. The App </h3>
						<p>a. To use the App, you must first install the Google Chrome web browser, or another web browser that is
							compatible with the MetaMask browser extension. You will need to install MetaMask to purchase cards during the
							Presale. MetaMask is an electronic wallet, which lets you purchase (either directly via Coinbase if you are in
							the United States, or via other third-party sites), store, and engage in transactions using Ethereum
							cryptocurrency. You will not be able to engage in any transactions on the App other than through MetaMask (or
							other Ethereum-compatible browsers). The App will only recognize you as a user, and you will only be able to
							interact with the App, if your Ethereum electronic wallet is connected and unlocked through your MetaMask
							account. There is no other way to sign up as a user, or to interact directly with the App.
						</p>
						<p>b. Transactions that take place on the App are managed and confirmed via the Ethereum blockchain. You
							understand that your Ethereum public address will be made publicly visible whenever you engage in a transaction
							on the App.
						</p>
						<p>c. Decent Amusements neither owns or controls MetaMask, Coinbase, Google Chrome, the Ethereum network, Decentraland, or any other
							third-party site, product, or service that you might access, visit, or use for the purpose of enabling you to
							use the various features of the App. Decent Amusements will not be liable for the acts or omissions of any such third
							parties, nor will Decent Amusements be liable for any damage that you may suffer as a result of your transactions or
							any other interaction with any such third parties.
						</p>
						<p>d. You must provide accurate and complete registration information when you create an account for the App. By
							creating an account, you agree to provide accurate, current and complete account information about yourself, and
							to maintain and promptly update as necessary your account information. You are responsible for the security of
							your account and your MetaMask wallet (and other Ethereum wallets and accounts). If you become aware of any
							unauthorized use of your password or of your account with us, you agree to notify Decent Amusements immediately at
							info@wonderzone.io.
						</p>
                        <h3>3. Decentraland Wearables</h3>
						<p>a. Decent Amusements has used Smart Contracts to manage the crafting and minting of Decentraland Wearables tokens
							("Wearables").
						</p>
						<p>b. As part of the WonderMine game, players can "craft a wearable," for which they receive a Voucher Code. This Voucher Code can be used only
						once to claim a single wearable. If the player does not claim their wearable during game play, they can use the Voucher Code to claim it later on Decent
						Amusements website. It is the player's responsibility to keep their Voucher Code a secret. Otherwise Decent Amusements cannot be held responsible for lost or stolen
						Voucher Codes.
						</p>
						<h3>3. Fees and Payment </h3>
						<p>a. WonderZone Games may accept payments for virtual goods using blockchain-based cryptocurrencies, 
                            deployed as ERC-20 tokens on the Ethereum network, if you elect to
							purchase, trade, or interact with cryptocurrency transactions in the App, or with or from other users via the App, any
							financial transactions that you engage in will be conducted solely through the Ethereum network. Decent Amusements will
							have no insight into or control over these payments or transactions, nor does Decent Amusements have the ability to
							reverse such transactions. With that in mind, Decent Amusements will have no liability to you or to any third party for
							any claims or damages that may arise as a result of any transactions that you engage in via the App, or using
							the Smart Contracts, or any other transactions that you conduct via the Ethereum network or MetaMask.</p>
						<p>b. Ethereum requires the payment of a transaction fee (a "Gas Fee") for every transaction that occurs on the
							Ethereum network. The Gas Fee funds the network of computers that run the decentralized Ethereum network. This
							means that you will need to pay a Gas Fee for each transaction that occurs via the App.</p>
						<p>c. You will be solely responsible to pay any and all sales, use, value-added and other taxes, duties, and
							assessments (except taxes on our net income) now or hereafter claimed or imposed by any governmental authority
							(collectively, "Taxes") associated with your use of the App (including, without limitation, any Taxes that may
							become payable as the result of your ownership, transfer, or acquisition of WonderZone NFTs or Decentraland Wearables).
							Except for income taxes levied on Decent Amusements, you: (i) will pay or reimburse Decent Amusements for all national,
							federal, state, local or other taxes and assessments of any jurisdiction, including value added taxes and
							taxes as required by international tax treaties, customs or other import or export taxes, and amounts levied
							in lieu thereof based on charges set, services performed or payments made hereunder, as are now or hereafter
							may be imposed under the authority of any national, state, local or any other taxing jurisdiction; and (ii)
							shall not be entitled to deduct the amount of any such taxes, duties or assessments from payments made to us
							pursuant to these Terms.
						</p>
						<h3>4. Ownership and Restrictions </h3>
						<p>a. You acknowledge and agree that Decent Amusements (or, as applicable, our licensors) own all legal right, title
							and interest in and to all elements of the App, and all intellectual property rights therein. The visual
							interfaces, graphics (including, without limitation, all art and drawings associated with the), design,
							systems, methods, information, computer code, software, services, "look and feel", organization, compilation
							of the content, code, data, and all other elements of the App (collectively, the "Decent Amusements Materials") are
							owned by Decent Amusements, and are protected by copyright, trade dress, patent, and trademark laws, international
							conventions, other relevant intellectual property and proprietary rights, and applicable laws. All Decent Amusements
							Materials are the copyrighted property of Decent Amusements or its licensors, and all trademarks, service marks, and
							trade names contained in the Decent Amusements Materials are proprietary to Decent Amusements or its licensors. Except as
							expressly set forth herein, your use of the App does not grant you ownership of or any other rights with
							respect to any content, code, data, or other materials that you may access on or through the App. Decent Amusements
							reserves all rights in and to the Decent Amusements Materials not expressly granted to you in the Terms. For the sake
							of clarity, you understand and agree: (i) that your "purchase" of a Card Pack, whether via the App or
							otherwise, does not give you any rights or licenses in or to the Decent Amusements Materials (including, without
							limitation, our copyright in and to the art and drawings associated with that Card Pack or the associated
							cards unlocked through it) other than those expressly contained in these Terms; and (ii) that you do not have
							the right to reproduce, distribute, or otherwise commercialize any elements of the Decent Amusements Materials
							(including, without limitation, our copyright in and to the art and drawings associated with that Card Pack or
							Card) in any way without our prior written consent in each case, which consent Decent Amusements may withhold in our
							sole and absolute discretion.
						</p>
						<p>b. You may choose to submit comments, bug reports, ideas or other feedback about the App, including without
							limitation about how to improve the App (collectively, "Feedback"). By submitting any Feedback, you agree that
							Decent Amusements is free to use such Feedback at our discretion and without additional compensation to you, and to
							disclose such Feedback to third parties (whether on a non-confidential basis, or otherwise). You hereby grant
							us a perpetual, irrevocable, nonexclusive, worldwide license under all rights necessary for us to incorporate
							and use your Feedback for any purpose.
						</p>
						<p>c. You agree that you are responsible for your own conduct while accessing or using the App, and for any
							consequences thereof. You agree to use the App only for purposes that are legal, proper and in accordance with
							these Terms and any applicable laws or regulations. By way of example, and not as a limitation, you may not,
							and may not allow any third party to: (i) send, upload, distribute or disseminate any unlawful, defamatory,
							harassing, abusive, fraudulent, obscene, or otherwise objectionable content; (ii) distribute viruses, worms,
							defects, Trojan horses, corrupted files, hoaxes, or any other items of a destructive or deceptive nature;
							(iii) impersonate another person (via the use of an email address or otherwise); (iv) upload, post, transmit
							or otherwise make available through the App any content that infringes the intellectual proprietary rights of
							any party; (v) use the App to violate the legal rights (such as rights of privacy and publicity) of others;
							(vi) engage in, promote, or encourage illegal activity (including, without limitation, money laundering);
							(vii) interfere with other users" enjoyment of the App; (viii) exploit the App for any unauthorized commercial
							purpose; (ix) modify, adapt, translate, or reverse engineer any portion of the App; (x) remove any copyright,
							trademark or other proprietary rights notices contained in or on the App or any part of it; (xi) reformat or
							frame any portion of the App; (xii) display any content on the App that contains any hate-related or violent
							content or contains any other material, products or services that violate or encourage conduct that would
							violate any criminal laws, any other applicable laws, or any third party rights; (xiii) use any robot, spider,
							site search/retrieval application, or other device to retrieve or index any portion of the App or the content
							posted on the App, or to collect information about its users for any unauthorized purpose; (xiv) create user
							accounts by automated means or under false or fraudulent pretenses; or (xv) access or use the App for the
							purpose of creating a product or service that is competitive with any of our products or services. If you are
							in breach of the terms listed above, you can be banned from accessing the App in the future.
						</p>
						<h3>5. Termination </h3>
						<p>
							You may terminate these Terms at any time by canceling your account on the App and discontinuing your access
							to and use of the App. You will not receive any refunds if you cancel your account, or otherwise terminate
							these Terms. You agree that Decent Amusements, at our sole discretion and for any or no reason, may terminate these
							Terms and suspend and/or terminate your account(s) for the App. You agree that any suspension or termination
							of your access to the App may be without prior notice, and that Decent Amusements will not be liable to you or to any
							third party for any such suspension or termination. If Decent Amusements terminates these Terms or suspend or
							terminate your access to or use of the App due to your breach of these Terms or any suspected fraudulent,
							abusive, or illegal activity, then termination of these Terms will be in addition to any other remedies
							Decent Amusements may have at law or in equity. Upon any termination or expiration of these Terms, whether by you or
							Decent Amusements, you may no longer have access to information that you have posted on the App or that is related to
							your account, and you acknowledge that Decent Amusements will have no obligation to maintain any such information in
							our databases or to forward any such information to you or to any third-party. Sections 3.C and 4 through 15
							will survive the termination or expiration of these Terms for any reason.
						</p>
						<h3>6. Disclaimers </h3>
						<p>
							a. YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE APP IS AT YOUR SOLE RISK, AND THAT
							THE APP IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO
							THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, Decent Amusements, OUR SUBSIDIARIES, AFFILIATES, AND
							LICENSORS MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE APP AND ANY PART
							OF IT (INCLUDING, WITHOUT LIMITATION, THE SITE, ANY SMART CONTRACT, OR ANY EXTERNAL WEBSITES), INCLUDING THE
							IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS,
							ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, Decent Amusements, OUR SUBSIDIARIES,
							AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE APP WILL
							MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE APP WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE
							FROM ERROR, (III) USAGE DATA PROVIDED THROUGH THE APP WILL BE ACCURATE, (III) THE APP OR ANY CONTENT,
							SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE APP ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR
							(IV) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE APP WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE
							EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT
							APPLY TO YOU.
						</p>
						<p>
							b. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND
							AGREE THAT Decent Amusements HAS NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR
							GROSS NEGLIGENCE.
						</p>
						<p>
							c. IT IS THE PLAYER'S RESPONSIBILITY TO KEEP THEIR OWN ETHEREUM ACCOUNTS, AND THE ASSETS HELD IN THEM, SAFE. 
							Decent Amusements WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF
							THE ETHEREUM NETWORK OR THE METAMASK ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR
							CLAIMS ARISING FROM: (A) USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY CONSTRUED SMART CONTRACTS OR
							OTHER TRANSACTIONS; (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES; (D) UNAUTHORIZED ACCESS OR
							ACTIVITIES BY THIRD PARTIES, INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES, PHISHING, BRUTEFORCING, TRANSACTION REDIRECTING, 
							OR OTHER MEANS OF ATTACK AGAINST THE APP, ETHEREUM NETWORK, OR THE METAMASK ELECTRONIC WALLET.
						</p>
						<p>
							d. DECENTRALAND WEARABLES ARE ERC-721 TOKENS WHOSE SMART CONTRACTS ARE CREATED, OWNED, AND MANAGED BY THE 
							DECENTRALAND FOUNDATION. Decent Amusements HAS NO CONTROL OVER AND MAKES NO GUARANTEES OR PROMISES WITH
							RESPECT TO DECENTRALAND SMART CONTRACTS.
						</p>
						<p>
							e. Decent Amusements IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY OTHER FEATURES OF THE ETHEREUM NETWORK
							OR THE METAMASK ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES
							(OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE ETHEREUM NETWORK, INCLUDING FORKS,
							TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
						</p>
						<p>
							f. Decent Amusements IS NOT RESPONSIBLE FOR LOSSES DUE TO PLAYERS LOSING ACCESS TO THEIR OWN ETHEREUM ACCOUNTS,
							OR HAVING SUCH ACCOUNTS, OR TRANSACTIONS PENDING FROM SUCH ACCOUNTS, REDIRECTED TO OTHERS. 
						</p>
						<h3>7. Limitation of Liability </h3>

						<p>a. YOU UNDERSTAND AND AGREE THAT Decent Amusements, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS WILL NOT BE LIABLE
							TO YOU OR TO ANY THIRD-PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH
							YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF
							PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST
							OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF Decent Amusements HAVE BEEN
							ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </p>
						<p>b. YOU AGREE THAT Decent Amusements" TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR
							RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE
							APP, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF (A)
							THE AMOUNTS YOU ACTUALLY PAID US UNDER THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE,
							OR (B) US $50. </p>
						<p>c. YOU ACKNOWLEDGE AND AGREE THAT Decent Amusements HAS MADE THE APP AVAILABLE TO YOU AND ENTERED INTO THESE TERMS
							IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A
							REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN THE PARTIES AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN
							THE PARTIES. Decent Amusements WOULD NOT BE ABLE TO PROVIDE THE APP TO YOU WITHOUT THESE LIMITATIONS. </p>
						<p>d. YOU ACKNOWLEDGE AND AGREE THAT YOU DO NOT RESIDE IN REGION THAT EXPLICITLY BANS THE USE OF LOOT BOXES IN
							GAMES IN ACCORDANCE WITH GAMBLING LAWS. </p>
						<p>e. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, AND
							SOME JURISDICTIONS ALSO LIMIT DISCLAIMERS OR LIMITATIONS OF LIABILITY FOR PERSONAL INJURY FROM CONSUMER
							PRODUCTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO PERSONAL INJURY CLAIMS. </p>


						<h3>8. Assumption of Risk </h3>
						<p>You accept and acknowledge each of the following: </p>
						<p>a. The prices of blockchain assets are extremely volatile. Fluctuations in the price of other digital assets
							could materially and adversely affect the value of your Decentraland Wearables and other WonderZone NFT items, 
							which may also be subject to significant price volatility. Decent Amusements cannot guarantee that any purchasers 
							of Decentraland Wearables or WonderZone NFTs will not lose money. </p>
						<p>b. You are solely responsible for determining what, if any, taxes apply to WonderZone-related
							transactions. Decent Amusements is not responsible for determining the taxes that apply to your transactions on the
							App, the Site, or the Smart Contracts. </p>
						<p>c. The App does not store, send, or receive Decentraland Wearables or WonderZone ERC-721 tokens. 
						    Those tokens
							exist only by virtue of the ownership record maintained on the App"s supporting blockchain in the Ethereum
							network. Any transfer of ERC-721 tokens occurs within the supporting blockchain in the
							Ethereum network, and not on the App. </p>
						<p>d. There are risks associated with using an Internet-based currency, including, but not limited to, the risk
							of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that
							third parties may obtain unauthorized access to information stored within your wallet. You accept and
							acknowledge that Decent Amusements will not be responsible for any communication failures, disruptions, errors,
							distortions or delays you may experience when using the Ethereum network, however caused. </p>
						<p>e. A lack of use or public interest in the creation and development of distributed ecosystems could
							negatively impact the development of the Decent Amusements ecosystem, and therefore the potential utility or value of
							Wonderzone-related NFTs. </p>
						<p>f. The regulatory regime governing blockchain technologies, cryptocurrencies, and tokens is uncertain, and
							new regulations or policies may materially adversely affect the development of the WonderZone ecosystem,
							and therefore the potential utility or value of Decentraland Wearables and WonderZone NFTs. </p>
						<p>g. Upgrades by Ethereum to the Ethereum platform, a hard fork in the Ethereum platform, or a change in how
							transactions are confirmed on the Ethereum platform may have unintended, adverse effects on all blockchains
							using the ERC-20 and ERC-721 standards, including the WonderZone Games ecosystem. </p>

						<h3>9. Indemnification </h3>
						<p>You agree to hold harmless and indemnify Decent Amusements and its subsidiaries, affiliates, officers, agents,
							employees, advertisers, licensors, suppliers or partners from and against any claim, liability, loss, damage
							(actual and consequential) of any kind or nature, suit, judgment, litigation cost, and attorneys" fees arising
							out of or in any way related to (i) your breach of these Terms, (ii) your misuse of the App, or (iii) your
							violation of applicable laws, rules or regulations in connection with your access to or use of the App. You
							agree that Decent Amusements will have control of the defense or settlement of any such claims. </p>

						<h3>10. External Sites </h3>
						<p>The App may include hyperlinks to other web sites or resources (collectively, "External Sites"), which are
							provided solely as a convenience to our users. Decent Amusements has no control over any External Sites. You
							acknowledge and agree that Decent Amusements is not responsible for the availability of any External Sites, and that
							Decent Amusements does not endorse any advertising, products or other materials on or made available from any
							External Sites. Furthermore, you acknowledge and agree that Decent Amusements is not liable for any loss or damage
							which may be incurred as a result of the availability or unavailability of the External Sites, or as a result
							of any reliance placed by you upon the completeness, accuracy or existence of any advertising, products or
							other materials on, or made available from, any External Sites. </p>

						<h3>11. Changes to the App </h3>
						<p>Decent Amusements may make changes to the Terms from time to time. When Decent Amusements makes changes, Decent Amusements will
							make the updated Terms available on the App and update the "Last Updated" date at the beginning of these Terms
							accordingly. Please check these Terms periodically for changes. Any changes to the Terms will apply on the
							date that they are made, and your continued access to or use of the App after the Terms have been updated will
							constitute your binding acceptance of the updates. If you do not agree to any revised Terms, you may not
							access or use the App. </p>

						<h3>12. Children </h3>
						<p>You affirm that you are over the age of 13, as the App is not intended for children under 13. IF YOU ARE 13
							OR OLDER BUT UNDER THE AGE OF 18, OR THE LEGAL AGE OF MAJORITY WHERE YOU RESIDE IF THAT JURISDICTION HAS AN
							OLDER AGE OF MAJORITY, THEN YOU AGREE TO REVIEW THESE TERMS WITH YOUR PARENT OR GUARDIAN TO MAKE SURE THAT
							BOTH YOU AND YOUR PARENT OR GUARDIAN UNDERSTAND AND AGREE TO THESE TERMS. YOU AGREE TO HAVE YOUR PARENT OR
							GUARDIAN REVIEW AND ACCEPT THESE TERMS ON YOUR BEHALF. IF YOU ARE A PARENT OR GUARDIAN AGREEING TO THE TERMS
							FOR THE BENEFIT OF A CHILD OVER 13, THEN YOU AGREE TO AND ACCEPT FULL RESPONSIBILITY FOR THAT CHILD"S USE OF
							THE APP, INCLUDING ALL FINANCIAL CHARGES AND LEGAL LIABILITY THAT HE OR SHE MAY INCUR. </p>

						<h3>13. Privacy Policy </h3>
						<p>Our Privacy Policy describes the ways Decent Amusements collects, use, store and disclose your personal
							information, and is hereby incorporated by this reference into these Terms. You agree to the collection, use,
							storage, and disclosure of your data in accordance with our Privacy Policy.</p>

						<h3>14. Dispute Resolution; Arbitration </h3>
						<p>Please read this Section 14 carefully. It requires you to arbitrate disputes with Decent Amusements, and limits the
							manner in which you can seek relief from us. All disputes arising out of or in connection with these Terms,
							including without limitation your access or use of the App, the Site, or the Smart Contracts, or to any
							products sold or distributed through the App, the Site, or the Smart Contracts, will be referred to and
							finally resolved by arbitration under the rules of the State of California. The appointing authority will be the
							State of California. The case will be adjudicated by a single arbitrator and will be administered by the State of
							California in accordance with its applicable rules. Each party will cover its own fees and costs associated with
							the arbitration proceedings; however, if the arbitrator finds that you cannot afford to pay the fees and costs
							reasonably associated with the arbitration proceedings, Decent Amusements will pay them for you. The place of
							arbitration will be San Francisco, California, United States. You may choose to have the arbitration conducted by
							telephone, based on written submissions. The language of the arbitration will be English. The award of the
							arbitrator will be final and binding, and any judgment on the award rendered by the arbitrator may be entered
							in any court of competent jurisdiction. Notwithstanding the foregoing, Decent Amusements may seek and obtain
							injunctive relief in any jurisdiction in any court of competent jurisdiction, and you agree that these Terms
							are specifically enforceable by Decent Amusements through injunctive relief and other equitable remedies without
							proof of monetary damages.</p>

						<p>WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO THESE TERMS, INCLUDING WITHOUT LIMITATION DISPUTES
							RELATED TO THE APP, THE SITE, THE SMART CONTRACTS, OR ANY PRODUCTS SOLD OR DISTRIBUTED THROUGH THE APP, THE
							SITE, OR THE SMART CONTRACTS: (I) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY; AND (II)
							YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT,
							INCLUDING BUT NOT LIMITED TO CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE. </p>

						<h3>15. General </h3>
						<p>These Terms constitute the entire legal agreement between you and Decent Amusements, govern your access to and use
							of the App, and completely replace any prior or contemporaneous agreements between the parties related to your
							access to or use of the App, whether oral or written. There are no third-party beneficiaries to these Terms.
							The parties are independent contractors, and nothing in these Terms create any agency, partnership, or joint
							venture. The language in these Terms will be interpreted as to its fair meaning, and not strictly for or
							against any party. You may not assign any or your rights or obligations under these Terms, whether by
							operation of law or otherwise, without our prior written consent. Decent Amusements may assign our rights and
							obligations under these Terms in our sole discretion to an affiliate, or in connection with an acquisition,
							sale or merger. Should any part of these Terms be held invalid or unenforceable, that portion shall be
							construed consistent with applicable law and the remaining portions will remain in full force and effect. Our
							failure to enforce any provision of these Terms will not be deemed a waiver of such provision, nor of the
							right to enforce such provision. These Terms will be governed by and construed in accordance with the laws of
							the State of California, United States and the federal laws of United States applicable therein, excluding its
							conflicts of law rules and principles. Subject to Section 14, any legal action or proceeding arising under
							these Terms will be brought exclusively in the federal or provincial courts located in Phoenix, California, and
							the parties irrevocably consent to the personal jurisdiction and venue there. Decent Amusements will not be liable
							for any failure or delayed performance of our obligations that result from any condition beyond our reasonable
							control, including, but not limited to, governmental action, acts of terrorism, earthquake, fire, flood, acts
							of God, labor conditions, power failures, Internet disturbances, or acts or omissions of third parties. You
							agree that Decent Amusements may provide you with notices (including, without limitation those regarding changes to
							these Terms) by email, regular mail, or postings on the App. By providing us with your email address, you
							consent to our using the email address to send you any notices required by law in lieu of communication by
							mail.</p>
        
                            </div>
                        </div>
                    </div>

                </div>

                </div>
            <div className="footer" id="footer">
              <div className="container text-center">
                  <div className="row footer-social">
                      <div className="col-lg-12">
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a href="https://twitter.com/WonderZoneGames" target="_blank" >
                              <i className="fab fa-twitter fa-fw fa-2x"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                              <a href="https://discord.gg/pRczkzh" target="_blank" >
                                <img src="img/discord-logo-white-128x128.fw.png" alt="" width="32"/>
                              </a>
                            </li>
                        </ul>
                      </div>
                    </div>
                {/* <div className="row">
                  <div className="col-lg-4 footer-contact-details mx-auto">
                      <div className="list-group">
                          <a href="https://www.wonderzone.io/privacy.html" className="list-group-item list-group-item-action ">
                            Privacy Policy
                          </a>
                          <a href="https://www.wonderzone.io/terms.html" className="list-group-item list-group-item-action">Terms of Use</a>
                        </div>
                  </div>
                </div> */}
        
                <p className="copyright">&copy; 2020 Decent Amusements LLC. All rights reserved. Terms of Use</p>
              </div>
            </div>

            </div>

        );
    }
}

export default TermsPage;
