import React, { useState } from 'react';
import { 
    Alert,
    Button, 
    Label,
    Input,
    Form,
    FormGroup,
    Row, 
    Col, 
    ListGroup, 
    ListGroupItem, 
    NavLink,
    UncontrolledDropdown, 
    Dropdown, 
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Spinner,
    Toast,
    ToastBody,
    ToastHeader
 } from 'reactstrap';
import { Link, Route } from 'react-router-dom';
import 'react-table/react-table.css';

import { config } from '../config/config.js';

import Web3 from 'web3'; // web3 from MetaMask or another provider
import NewWeb3 from '../module/NewWeb3Module.js';
import { object } from 'prop-types';

let web3 = null;
let newWeb3 = null;

class VoucherPage extends React.Component {

    constructor(props) {
        super(props);

        this.wearableNames = [
            "meteorchaser_transmitter_earring",
            "meteorite_dustmask_mask",
            "meteorchaser_shoes_feet",
            "meteorchaser_trousers_lower_body",
            "meteorite_protective_hardhat_hat",
            "meteorchaser_vest_upper_body",
            "steampunk_goggles",
            "steampunk_mask",
            "steampunk_boots",
            "steampunk_hat",
            "steampunk_trousers",
            "steampunk_jacket",
        ];

        this.newWeb3 = new NewWeb3(this, this.onWeb3Loaded);
        let defaultAccount;
        if (this.newWeb3)
        {
            defaultAccount = this.newWeb3.account;
        }

        this.state = {
            account: null,
            isOwner: false,
            voucher: "",
            sig: "",
            wearName: "",
            wearLabel: "",
            dropdownOpen: false,
            currentAlert: "",
            alertOpen: false,
            errorOpen: false,
            successOpen: false,
            alertText: "",
            errorText: "",
            successText: "",
            tx: ""
        };
        
        // this.signAndClaim = this.signAndClaim.bind(this);
        // this.signVoucher = this.signVoucher.bind(this);

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.onWearableSelected = this.onWearableSelected.bind(this);
        this.onVoucherChange = this.onVoucherChange.bind(this);
        this.onSigChange = this.onSigChange.bind(this);

        this.dismissAlert = this.dismissAlert.bind(this);
        this.showAlert = this.showAlert.bind(this);
    }

    onWeb3Loaded(self, acct, acctIsOwner)
    {
        console.log("Web3 loaded! account=" + acct); 
        if (self != null)
        {
            self.setState({
                account: acct,
                isOwner: acctIsOwner
            });
        }
    }

    async componentDidMount() {
        
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        // only continue if it's a new account
        //console.log("Updated. account=" + this.state.account + "; prev=" + prevState.account + ", isOwner=" + this.state.isOwner);

        if (this.state.account === prevState.account) return;

        let w3;
        let self = this;
        try {

            // get web3 provider
            if (this.newWeb3 && this.newWeb3.web3)
            {
                console.log("cd pre-check, account=" + this.state.account);
                w3 = this.newWeb3.web3;

                if (!this.state.account) return;

                this.newWeb3.doLogin(this.onLoggedIn, this);
            }
 
        } catch (err) {
            console.error(err)
        }
    }
    
    onLoggedIn(self, webToken)
    {
        console.log("Logged in!");

        let w3;
        try {
            // get web3 provider
            if (webToken)
            {
                console.log("webToken exists: " + JSON.stringify(webToken));

                let defaultOptions = {
                    method:'GET',
                    mode: 'cors',
                    crossDomain: true,
                    headers:new Headers({
                        'Access-Control-Allow-Origin': config.origin,
                        'Content-Type': 'application/json; charset=utf-8',
                        'x-access-token' : webToken.token 
                    })
                };

                // 2DO: Call playfab here?
                
                // var callStr = config.webService + 'api/creatures/web3/' + self.state.tokenId; 
                //console.log("calling: " + callStr);
                // fetch(callStr, defaultOptions)
                // .then((response) => { 
                //     // the response doesn't contain the creatures the json object does
                //     // console.log("creatures response = " + JSON.stringify(response));
                //     response.json().then(async json => {
                //         console.log("creature json = " + JSON.stringify(json));
                //         let newObj = await self.extractData(json);
                //         let isTokenOwner = (newObj.cryptoaccount == self.state.account);
                //         console.log("isTokenOwner=" + isTokenOwner);
                //         self.setState({
                //             selectedCard: newObj,
                //             isTransferable: newObj.isTransferable,
                //             isOwner: isTokenOwner
                //         }); 

                //     }).catch(() => alert("Error: 1 Failed to retrieve card info."));
                // })
                // .catch(() => alert("Error: 2 Failed to retrieve card info."));
            }
 
        } catch (err) {
            console.error(err);
        }
    }

    // signVoucher(self) 
    // {
    //     console.log("calling signVoucher");
    //     if (this.state.account === null || this.state.account === undefined || this.state.account === "0x0" ) {
    //         this.showError("Please login to MetaMask and refresh your web browser.");
    //         return;
    //     }

    //     this.showAlert("Calling signVoucher...");

    //     try { 
    //         //console.log("web3.version=" + this.newWeb3.web3.version)
    //         let voucher = this.state.voucher;
    //         //let sig = "";
    //         let wearName = this.state.wearName;

    //         this.newWeb3.signTransaction(voucher, wearName, function(error, json) {
    //             console.log("json=" + json);
    //         }).then (function (sig) 
    //         {
    //             // this.sig = signature;
    //             self.showAlert("Message was signed...");
    //             console.log("Signature: " + sig);

    //             self.newWeb3.wearCrafterContract.methods.checkSender(voucher, wearName, sig, false).call({from:self.state.account}).then(function(result)
    //             {
    //                 console.log(result);
    //                 if (result)
    //                 {
    //                     self.showAlert("Sender matched!");
    //                 }
    //                 else
    //                 {
    //                     self.showError("Sender did not match.");
    //                 }
    //             });
    //         });
    //         //console.log("returned sig: " + sig);
    //     } catch (error) {
    //         self.showError("Signature failed. Please try again later: " + error);
    //     }
    // }

    // signAndClaim(self) 
    // {
    //     console.log("calling signAndClaim");
    //     if (this.state.account === null || this.state.account === undefined || this.state.account === "0x0" ) {
    //         this.showError("Please login to MetaMask and refresh your web browser.");
    //         return;
    //     }

    //     this.dismissAll();

    //     try { 
    //         let voucher = this.state.voucher;
    //         let wearName = this.state.wearName;

    //         this.newWeb3.signTransaction(voucher, wearName, function(error, json) {
    //             //console.log("json=" + json);
    //         }).then (function (sig) 
    //         {

    //                 var promise = new Promise((resolve, reject) => {
    //                     //console.log("buy ");
    //                     // , gas: totalFee, gasPrice: gasPrice
    //                     self.newWeb3.wearCrafterContract.methods.craft(voucher, sig, false).send({from:self.state.account})
    //                     .on('transactionHash', function(hash){
    //                         console.log("transactionHash " + JSON.stringify(hash));
    //                         self.showEtherscanLink("Transaction submitted", hash);
    //                         //self.showAlert("Transaction was submitted. Waiting for confirmation...");
    //                     })
    //                     .on('confirmation', function(confirmationNumber, receipt){
    //                         console.log("confirmation " + JSON.stringify(confirmationNumber));
    //                         //self.showAlert("Transaction was confirmed. Waiting for receipt...");
    //                         self.showEtherscanLink("Transaction completed", self.state.tx);
    //                     })
    //                     .on('receipt', function(receipt){
    //                         //console.log("receipt " + JSON.stringify(receipt));
    //                         self.showEtherscanLink("Transaction received", self.state.tx);
    //                         resolve(receipt);
    //                     })
    //                     .on('error', function(error){
    //                         console.log("reject error " + JSON.stringify(error));
    //                         reject(error);
    //                     });
    //                 });
    //                 promise.then((result) => {
    //                     self.showSuccess("Success! Your wearable is on its way.");
    //                     // this.setState({
    //                     //     selectedCard: card
    //                     // });
    //                 }).catch((reason) => {
    //                     let msg;
    //                     console.log("type=" + typeof(reason));
    //                     if (typeof(reason) == "object")
    //                     {
    //                         if (reason["message"] != null)
    //                         {
    //                             msg = reason["message"];
    //                         }
    //                         else
    //                         {
    //                             let str = JSON.stringify(reason);
    //                             msg = str.substring(0,100) + "...";
    //                             // if (str.indexOf("reverted") >= 0)
    //                             // {
    //                             //     msg = "Transaction has been reverted by the EVM.";
    //                             // }
    //                             // else
    //                             // {
    //                             //     msg = str.substring(0,120) + "...";
    //                             // }
    //                         }
    //                     }
    //                     else
    //                     {
    //                         let errorStr = reason.toString(); // JSON.stringify(error);
    //                         console.log("|" + errorStr + "|");
    //                         let idx = errorStr.indexOf("{", 3);
    //                         console.log("idx=" + idx);
                            
    //                         if (idx >= 0)
    //                         {
    //                             msg = errorStr.substring(0, idx);
    //                         }
    //                         else
    //                         {
    //                             msg = errorStr;
    //                         }
    //                     }
    //                     self.showError('Transaction failed: '+ msg);

    //                 });
    
    //             // }).catch((error) => {
    //             //     // error is a string?
    //             //     let errorStr = error.toString(); // JSON.stringify(error);
    //             //     console.log("|" + errorStr + "|");
    //             //     let idx = errorStr.indexOf("{", 3);
    //             //     console.log("idx=" + idx);
    //             //     let msg;
    //             //     if (idx >= 0)
    //             //     {
    //             //         msg = errorStr.substring(0, idx);
    //             //     }
    //             //     else
    //             //     {
    //             //         msg = errorStr;
    //             //     }

    //             //     self.showError('Transaction failed: '+ msg);
    //             // });
    //         });

    //     } catch (error) {
    //         this.showError("Transaction failed. Please try again later: " + error);
    //     }
    // }

    toggleDropdown(item)
    {
        console.log("value=" + item.currentTarget);
        let isOpen = this.state.dropdownOpen;
        this.setState({
            dropdownOpen: !isOpen
        });
    }

    showAlert(msg)
    {
        console.log("showAlert(" + msg + ")")
        if (msg == null)
        {
            msg = this.state.alertText;
        }
        this.setState({
            // successOpen: false,
            // errorOpen:false, 
            alertOpen: true,
            alertText: msg,
            tx: "",
            txLink: ""
        });
    }

    showEtherscanLink(prefix, txId)
    {
        console.log("showEtherscanLink(" + txId + ")")
        let msg = prefix + ': ' // '{<a href="https://ropsten.etherscan.io/tx/' + txId + '" className="alert-link" target="_blank">' + txId + '</a>}';
        let linkTxt = 'https://etherscan.io/tx/' + txId;
        // let msg = prefix + " ";
        this.setState({
            // successOpen: false,
            // errorOpen:false, 
            alertOpen: true,
            alertText: msg,
            tx: txId,
            txLink: linkTxt
        });
    }

    showError(msg)
    {
        console.log("showError(" + msg + ")")
        if (msg == null)
        {
            msg = this.state.errorText;
        }
        this.setState({
            // alertOpen: false,
            successOpen:false, 
            errorOpen: true,
            errorText: msg
        });
    }

    showSuccess(msg)
    {
        console.log("showSuccess(" + msg + ")")
        if (msg == null)
        {
            msg = this.state.successText;
        }
        this.setState({
            // alertOpen: false,
            errorOpen:false, 
            successOpen: true,
            successText: msg
        });
    }

    dismissAlert(evt)
    {
        //let isOpen = this.state.alertOpen
        this.setState({
            currentAlert: "",
            alertOpen: false
        });
    }

    dismissError(evt)
    {
        //let isOpen = this.state.alertOpen
        this.setState({
            errorOpen: false
        });
    }

    dismissSuccess(evt)
    {
        //let isOpen = this.state.alertOpen
        this.setState({
            successOpen: false
        });
    }

    dismissAll(evt)
    {
        //let isOpen = this.state.alertOpen
        this.setState({
            alertOpen: false,
            errorOpen: false,
            successOpen: false,
            txId: ""
        });
    }

    onWearableSelected(dropdown)
    {
        let label = dropdown.currentTarget.textContent;
        let wearId = dropdown.currentTarget.id;
        console.log("Selected: " + label + " = " + wearId);
        
        this.setState({wearName: wearId, wearLabel: label});
        // this.showAlert("Selected wearable: " + label);
    }

    onVoucherChange(evt) 
    {
        //console.log("new value", evt.target.value);
        this.setState({
            voucher: evt.target.value
        });
    }

    onSigChange(evt) 
    {
        //console.log("new value", evt.target.value);
        this.setState({
            sig: evt.target.value
        });
    }

    render(){

        let submitText = "Claim Wearable";
        let txId = "";
		let url = "";
        let linkTag = "";
        if (this.state.tx != "")
        {
            txId = this.state.tx;
            url = "http://ropsten.etherscan.io/tx/" + txId;
            linkTag = <a href={url} class="alert-link" target="_blank">{txId}</a>;
        }

        return (
        <div className="fullpage bg-image" id="signVoucherPage">
            <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                <div className="container wow fadeIn">
                    <div className="text-center">
                        <h2>Wondermine Wearables</h2>
                        <p><span>Account: {this.state.account === undefined || this.state.account === null ? "...": this.state.account.toString().substring(0,10) + "..."}  {this.state.isOwner ? " (owner)": ""}</span></p>
                    </div>
                    {(this.state.account == null) ?
                        <div>Please connect your wallet, such as MetaMask or Dapper, and reload the page.</div>
                    :
                        <div>
                            <Row className="auction-card card-details-row" align="center" style={{ backgroundColor: '#dfdfdf', paddingBottom: '0.6rem', textAlign: "center" }}>
                                <Col sm="12">
                                    <div style={{ textAlign: 'center' }}>
                                        <h5>Claim With a Voucher</h5>
                                        <p><strong>This page is temporarily disabled. We will restore the Claim Voucher process soon!</strong></p>
                                        <div style={{ color: "#9999AA" }}>
                                        <p style={{ color: "gray" }}>Use this form to claim your wearable, if you weren't able to do it in the WonderMine game.</p>
                                        <ol>
                                        <li style={{ textAlign: 'left' }}>Enter your Voucher Code and the Wearable you crafted.</li>
                                        <li style={{ textAlign: 'left' }}>Sign the message using your wallet.</li> 
                                        <li style={{ textAlign: 'left' }}>Review the transaction. <b>If it shows an error, reject it!</b></li>
                                        <li style={{ textAlign: 'left' }}>If the transaction looks good, confirm it.</li>
                                        <li style={{ textAlign: 'left' }}>When the transaction completes, you've got your new wearable!</li>
                                        </ol>
                                        </div>
                                        <Form className="form">

                                            <FormGroup align="left">
                                            <Label size="md" style={{ textAlign: 'left'}}>VOUCHER CODE</Label>
                                            <Input
                                                type="text"
                                                name="voucher"
                                                id="voucher_field"
                                                bsSize="lg"
                                                placeholder="xxxx-xxxx-xxxx"
                                                style={{width:"300px", fontWeight:"bold"}}
                                                onChange={this.onVoucherChange}
                                            />
                                            </FormGroup>
{/* 
                                            <FormGroup align="left">
                                            <Label style={{ textAlign: 'left' }} for="sig_field">Signature</Label>
                                            <Input
                                                type="text"
                                                name="sig"
                                                id="sig_field"
                                                placeholder="0x123xyz..."
                                                onChange={this.onSigChange}
                                            />
                                            </FormGroup> */}

                                            <FormGroup align="left">
                                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                                <DropdownToggle caret outline>
                                                    Wearable
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem header>METEOR CHASER</DropdownItem>
                                                    <DropdownItem onClick={this.onWearableSelected} id="meteorchaser_transmitter_earring">MC Ear Transmitters (meteorchaser_transmitter_earring)</DropdownItem>
                                                    <DropdownItem onClick={this.onWearableSelected} id="meteorite_dustmask_mask">MC Dust Mask (meteorite_dustmask_mask)</DropdownItem>
                                                    <DropdownItem onClick={this.onWearableSelected} id="meteorchaser_shoes_feet">MC Shoes (meteorchaser_shoes_feet)</DropdownItem>
                                                    <DropdownItem onClick={this.onWearableSelected} id="meteorchaser_trousers_lower_body">MC Trousers (meteorchaser_trousers_lower_body)</DropdownItem>
                                                    <DropdownItem onClick={this.onWearableSelected} id="meteorite_protective_hardhat_hat">MC Hard Hat (meteorite_protective_hardhat_hat)</DropdownItem>
                                                    <DropdownItem onClick={this.onWearableSelected} id="meteorchaser_vest_upper_body">MC Jacket (meteorchaser_vest_upper_body)</DropdownItem>
                                                    <DropdownItem header>STEAMPUNK</DropdownItem>
                                                    <DropdownItem onClick={this.onWearableSelected} id="steampunk_goggles">Steampunk Goggles (steampunk_goggles)</DropdownItem>
                                                    <DropdownItem onClick={this.onWearableSelected} id="steampunk_mask">Steampunk Mask (steampunk_mask)</DropdownItem>
                                                    <DropdownItem onClick={this.onWearableSelected} id="steampunk_boots">Steampunk Boots (steampunk_boots)</DropdownItem>
                                                    <DropdownItem onClick={this.onWearableSelected} id="steampunk_hat">Steampunk Hat (steampunk_hat)</DropdownItem>
                                                    <DropdownItem onClick={this.onWearableSelected} id="steampunk_trousers">Steampunk Trousers (steampunk_trousers)</DropdownItem>
                                                    <DropdownItem onClick={this.onWearableSelected} id="steampunk_jacket">Steampunk Jacket (steampunk_jacket)</DropdownItem>                                                    
                                                </DropdownMenu>
                                                </Dropdown>
                                                <Input
                                                    type="text"
                                                    name="wearLabel"
                                                    id="wear_label_field"
                                                    bsSize="md"
                                                    placeholder="Wearable Name"
                                                    style={{width:"500px", fontWeight:"bold"}}
                                                    disabled={true}
                                                    value={this.state.wearLabel}
                                                />
{/* 
                                                <div className="text-left d-flex align-items-center">
                                                    <p> {this.state.wearName}</p>
                                                </div> */}

                                            </FormGroup>

                                            <Row align="center">
                                            {/* {'  '}<Button id="signButton" size="sm" color="secondary" style={{margin:"10px"}} disabled={false} onClick={() => this.signVoucher(this)}>Sign Voucher</Button>{' '} */}
                                            <Button id="signAndClaimButton" size="sm" color="primary" disabled={true} onClick={() => this.signAndClaim(this)} style={{margin:"20px"}}>Sign and Claim</Button>
                                            </Row>
                                        </Form>

                                     </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* <Alert color="primary" id="submitted_alert" isOpen={this.state.currentAlert == "submitted_alert"} toggle={this.dismissAlert} fade={true}>
                                    Transaction has been submitted. Waiting for confirmation...
                                </Alert> */}
                                <Alert color="primary" id="status_alert" isOpen={this.state.alertOpen} toggle={() => this.dismissAlert()} fade={true}>
                                    {this.state.alertText}{linkTag}
                                </Alert>
                                <Alert color="danger" id="error_alert" isOpen={this.state.errorOpen} toggle={() => this.dismissError()} fade={true}>
                                    {this.state.errorText}
                                </Alert>
                                <Alert color="success" id="success_alert" isOpen={this.state.successOpen} toggle={() => this.dismissSuccess()} fade={true}>
                                    {this.state.successText}
                                </Alert>
                            </Row>
                        </div>
                    }
                    </div>
                    <div className="footer" id="footer">

                        <div className="container text-center">
                            <div className="row footer-social">
                                <div className="col-lg-12">
                                    <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a href="https://discord.gg/pRczkzh" target="_blank" >
                                            <img src="img/discord-logo-white-128x128.fw.png" alt="" width="32"/>
                                        </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <NavLink href="/terms">Terms of Use</NavLink>
                            <p className="copyright">&copy; 2020 Decent Amusements LLC. All rights reserved.</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        );
    }
}

export default VoucherPage;
