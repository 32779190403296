import Web3 from 'web3';
import docCookies from '../js/cookies.js';
import { config } from '../config/config.js';

var Web3Utils = require('web3-utils');

var wearCrafterAbi = require("../build/contracts/WearableCrafter");

class NewWeb3 {

    constructor(caller, callbackFunction)
    {
        this.web3 = null;
        this.account = null;
        this.webToken = {};
        this.utils = Web3Utils;
        this.owner = null;
        this.isOwner = false;
        this.caller = caller;
        this.callbackFunction = callbackFunction;

        this.getWeb3().then(() => {
            //console.log("CALLBACK");
            //callbackFunction(caller, this.account, this.isOwner);
        });
    }

    async getWeb3()
    {
        let w3;
        // Modern dapp browsers...
        if (window.ethereum) {
            console.log("FOUND window.ethereum");
            w3 = new Web3(window.ethereum);
            this.web3 = w3;

            try {
                // Request account access if needed
                await window.ethereum.enable();

                // Acccounts now exposed
                await w3.eth.getAccounts().then(async (accounts) => {
                    //console.log("account.length = " + accounts.length + "; [0] = " + accounts[0]);
                    this.account = accounts[0];
                    await this.addWearCrafterContract();       
                });

                if (this.callbackFunction != null)
                {
                    console.log("caller=" + this.caller);
                    if (this.caller != null)
                    {
                        this.callbackFunction(this.caller, this.account, this.isOwner);
                    }
                }

            } catch (error) {
                // User denied account access...
                console.log("User denied account access");
            }
        }
        // Legacy dapp browsers...
        else if (window.web3) {
            console.log("LEGACY web3");
            w3 = new Web3(window.web3.currentProvider);
            this.web3 = w3;

            // Acccounts always exposed
            await w3.eth.getAccounts().then(async (accounts) => {
                console.log("account.length = " + accounts.length + "; [0] = " + accounts[0]);
                this.account = accounts[0];
                await this.addWearCrafterContract();
            });
        }
        // Non-dapp browsers...
        else {
            console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
    }

    async getDefaultAccount()
    {
        this.web3.eth.getAccounts().then((account) =>{
            //console.log('### accounts=' + account + " length=" + account.length);
           this.account = account[0];

           return this.account;
        });
    }

    async addWearCrafterContract()
    {
        this.wearCrafterContract = new this.web3.eth.Contract(wearCrafterAbi.abi, config.wearCrafterContract);

        await this.wearCrafterContract.methods.owner().call().then((resultOwner) => { 
            console.log("---- OWNER IS " + resultOwner);
            this.owner = resultOwner;
            this.isOwner = (this.owner == this.account);
        });
    }

    async signTransaction(voucher, wearableId, callback)
    {
        var hash = this.web3.utils.soliditySha3(voucher, wearableId);
        var signature;
        console.log("hash=" + hash);
        await this.web3.eth.personal.sign(hash, this.account, callback).then((sig) => {
            console.log("sig=" + sig);
            signature = sig;
        });
        return signature;
    }

    async doLogin(callbackFunction, caller)
    {
        this.preLogin().then((webToken) => {
            // webToken not returning correctly! But it is being stored in this.webToken
            //console.log("webToken=" + JSON.stringify(webToken));
            //console.log("this.webToken=" + JSON.stringify(this.webToken));
            callbackFunction(caller, this.webToken);
        })
    }

    async preLogin() 
    { 
        // login to PlayFab?
        if (!this.account)
        {
            console.log("Default account is null: " + this.account);
            return;
        }
        console.log("preLogin check");
        let fakeToken = { "token": "No login required" };
        //self.webToken = fakeToken;
        return fakeToken;

        // let defaultOptions = {
        //     method:'POST',
        //     mode: 'cors',
        //     crossDomain: true,
        //     headers:new Headers({
        //         'Access-Control-Allow-Origin': config.origin,
        //         'Content-Type': 'application/json; charset=utf-8'
        //     }),
        //     body: JSON.stringify({
        //         cryptoaccount: this.account
        //     })
        // };
        // let self = this;
        // await fetch(config.webService + 'api/preLogin', defaultOptions)
        // .then(async (response) => { 
        //     console.log("preLogin response " + JSON.stringify(response));
        //     await response.json().then(async (json) => {

        //         if (json.status === "error") {
        //             // player not found
        //             // add refcode to the call parameters
        //             var cbRefCode = docCookies.getItem("cbRefCode");
        //             var ref = "";
        //             if (cbRefCode !== null && this.utils.isAddress(cbRefCode))
        //             {
        //                 ref = cbRefCode;
        //             }

        //             defaultOptions.body = JSON.stringify({
        //                 cryptoaccount: this.account,
        //                 referralAccount: ref
        //             });
        //             //console.log("defaultOptions: " + defaultOptions.body);

        //             await fetch(config.webService + 'api/preRegister', defaultOptions)
        //             .then(async (response) => { 
                        
        //                 await response.json().then(async (json) => {
        //                     if (json.status !== "success") {
        //                         console.log(json.message);
        //                         return null;
        //                     } else {
        //                         self.webToken = json;
        //                         //console.log("token register " + JSON.stringify(json));
        //                         return json;
        //                     }

        //                 }).catch(() => console.log("preLogin Json Failed"));

        //             }).catch(() => console.log("preLogin Failed"));   

        //         } else {
        //             // player found

        //             //console.log("token login " + JSON.stringify(json));
        //             self.webToken = json;
        //             //return json;
                    
        //             // REFERRAL LOGIC -- DO WE NEED THIS?
        //             // add refcode to the call parameters
        //             var cbRefCode = docCookies.getItem("cbRefCode");
        //             var ref = "";
        //             if (cbRefCode !== null && this.utils.isAddress(cbRefCode))
        //             {
        //                 ref = cbRefCode;
        //             }

        //             if (self.account && ref) {
        //                 //console.log("token register " + JSON.stringify(json));
        //                 let defaultOptions = {
        //                     method:'PUT',
        //                     mode: 'cors',
        //                     crossDomain: true,
        //                     headers:new Headers({
        //                         'Access-Control-Allow-Origin': config.origin,
        //                         'Content-Type': 'application/json; charset=utf-8',
        //                         'x-access-token' : json.token 
        //                     }),                       
        //                     body: JSON.stringify({
        //                         cryptoaccount: self.account,
        //                         referralAccount: ref
        //                     })
        //                 };

        //                 fetch(config.webService + 'api/member/referral', defaultOptions)
        //                 .then(response => { 
        //                     //console.log("referral response (1): " + JSON.stringify(response));
        //                     response.json().then(json => {

        //                         if (json.status === "error") {
        //                             console.log("referral json error " + JSON.stringify(json));
        //                         } else {
        //                             console.log("referral json " + JSON.stringify(json));
        //                         }

        //                     }).catch(() => console.log("Referral response failed"));
        //                 }).catch(() => console.log("Referral Failed"));
        //             }
        //             return json;
                    
        //         }
        //     }).catch(() => console.log("preLogin Json Failed"));
        // }).catch(() => console.log("preLogin Failed"));
    }
}

export default NewWeb3;